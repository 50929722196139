<script setup lang="ts">
const title = 'Сервис по проверке HardSkills разработчиков'
const description =
  'Помогаем HRам за 1 минуту провести предквалификацию и определить технический уровень соискателя'

useSeoMeta(() => ({
  title,
  description,
  ogTitle: `${title} | FoxTailBox`,
  ogDescription: description,
}))

// Composable
const route = useRoute()
const router = useRouter()
const { showModal } = useModal()

// Data
const activeSection = computed(() => route.query.to)
const mainRef = ref()

// Actions
const scrollToActiveSection = async () => {
  if (activeSection.value) {
    await nextTick()
    const element = document.getElementById(String(activeSection.value))
    element && element.scrollIntoView({ behavior: 'smooth' })
    await router.push({ name: 'index' })
  }
}

const showRequest = async () => {
  const modal = await import('~/components/business/evaluation/modal/evaluation-modal-request.vue')
  showModal(modal)
}

// Hooks
//@TODO
useSafeOnMounted(mainRef, () => {
  scrollToActiveSection()
})

watch(activeSection, () => scrollToActiveSection())
</script>

<template>
  <main ref="mainRef">
    <!-- HERO -->
    <section class="flex flex-col gap-5 px-5 py-10 lg:px-40 lg:pb-16 lg:pt-20">
      <h1 class="font-arial text-[48px] leading-[61px] md:text-[76px] lg:leading-[84px]">
        Foxtail<span
          class="bg-gradient-to-r from-[#FFBB36] to-[#FF6203] bg-clip-text text-transparent"
          >Box</span
        >
      </h1>
      <h2 class="text-2xl tracking-[1px] text-madras opacity-75 md:text-3xl md:text-black">
        Сервис по проверке HardSkills разработчиков
      </h2>
    </section>

    <section class="mb-8 min-h-[460px] md:mb-0">
      <div class="rounded-5xl bg-hero-mobile bg-cover md:h-[269px] md:bg-hero md:bg-center">
        <div class="flex flex-col justify-center gap-5 px-5 py-7 md:flex-row md:gap-10 md:pt-16">
          <Card primary class="md:shadow-lg lg:max-w-[369px]">
            <div class="flex flex-col gap-4 px-5 pb-8 pt-7 text-center">
              <p class="text-headline font-bold leading-6">Самостоятельно</p>
              <p class="text-lg">Автоматическая проверка по&nbsp;коду из&nbsp;репозитория</p>
              <p class="rounded-xl bg-black-squeeze px-4 pb-2 pt-1">
                Дарим <span class="font-bold">5 бесплатных</span> проверок при&nbsp;регистрации
              </p>
              <div>
                <NuxtLink :to="{ name: 'evaluation' }">
                  <Button class="px-14 py-4"> Проверить</Button>
                </NuxtLink>
              </div>
            </div>
          </Card>

          <Card class="md:shadow-lg lg:max-w-[369px]">
            <div class="flex flex-col gap-5 px-8 pb-8 pt-7 text-center lg:justify-between">
              <p class="text-headline font-bold leading-6">С помощью наших экспертов</p>
              <p class="text-lg">Живое техническое интервью по&nbsp;запросу</p>
              <div>
                <Button class="px-14 py-4" color="gray" @click="showRequest"> Заказать</Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </section>

    <!-- CONTAINER -->
    <div id="about" class="flex scroll-mt-20 flex-col gap-20 p-5 pb-16 md:gap-20 md:py-10 lg:px-40">
      <!-- QUESTIONS -->
      <section class="flex flex-col gap-5 pt-10 md:pt-0">
        <p class="text-headline-mobile font-bold md:text-headline-big">Какие задачи решаем?</p>

        <div
          class="flex flex-col gap-5 rounded-5xl bg-black-squeeze px-5 py-10 md:flex-row md:gap-14 md:px-24"
        >
          <p
            class="flex h-12 w-12 min-w-[48px] items-center justify-center rounded-full border border-black px-4 py-3 text-xl font-bold"
          >
            1
          </p>
          <div class="flex flex-col gap-5">
            <p class="text-bolder font-bold lg:text-headline">
              Технический скрининг без привлечения технического специалиста
            </p>
            <p class="lg:pr-24 lg:text-base">
              Помогаем HRам за 1 минуту провести предквалификацию и определить технический уровень
              соискателя
            </p>
          </div>
        </div>

        <div
          class="flex flex-col gap-5 rounded-5xl bg-black-squeeze px-5 py-10 md:flex-row md:gap-14 md:px-24"
        >
          <p
            class="flex h-12 w-12 min-w-[48px] items-center justify-center rounded-full border border-black px-4 py-3 text-xl font-bold"
          >
            2
          </p>
          <div class="flex flex-col gap-5">
            <p class="text-bolder font-bold lg:text-headline">
              Определение технических навыков на живом интервью с нашим техническим экспертом
            </p>
            <p class="lg:pr-24 lg:text-base">
              Даем вам нужную экспертизу, чтобы подобрать специалиста
            </p>
          </div>
        </div>
      </section>

      <!-- ABOUT -->
      <section class="flex flex-col gap-5">
        <div class="flex flex-col gap-5 md:gap-0">
          <div class="flex flex-col gap-5 md:flex-row md:gap-7 md:pb-8 lg:items-center">
            <div class="flex max-w-[464px] flex-col gap-5 md:w-[50%]">
              <h3 class="text-headline-mobile font-bold md:text-headline-big">
                Сервис автоматической проверки
              </h3>
              <div class="flex flex-col gap-5">
                <p class="text-bolder font-bold">
                  Алгоритмы ML обучены проверять код разработчика на соответствие заявленному уровню
                </p>
                <p class="text-mobile md:text-base">
                  Важно понимать, что это не замена техническому интервью, а его обширное дополнение
                </p>
              </div>
            </div>
            <div
              class="flex flex-1 items-center justify-center bg-languages-mobile bg-cover bg-left px-11 py-11 lg:flex lg:items-center lg:bg-languages lg:bg-[auto] lg:bg-center lg:px-0 lg:py-0 lg:pb-24 lg:pt-20"
            >
              <div
                class="flex flex-col gap-2 rounded-5xl bg-white px-5 pb-2 pt-4 lg:max-w-[278px] lg:translate-x-6 lg:px-6 lg:pb-6 lg:pt-8"
              >
                <p class="text-base">Доступные языки программирования для проверки:</p>
                <ul class="list grid grid-cols-2 gap-x-6 pb-3 text-bolder font-bold">
                  <li>PHP</li>
                  <li>Java</li>
                  <li>Javascript</li>
                  <li>Swift</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row-reverse md:gap-16">
          <div class="flex flex-1 flex-col gap-8">
            <p class="text-base md:text-base">
              Пользователь загружает ссылку на публичный репозиторий, где размещен какой-то один
              конкретный проект
            </p>
            <p class="text-base md:text-base">
              В репозитории оцениваются параметры, по которым косвенно можно отследить
              профессионализм специалиста:
            </p>
            <ul class="list-small">
              <li>Читабельность кода</li>
              <li>Поддерживаемость кода</li>
              <li>Оптимальность алгоритма</li>
              <li>Документация кода</li>
              <li>Покрытие кода тестами</li>
            </ul>
          </div>
          <div class="flex flex-col gap-10 md:w-[50%]">
            <img
              class="bg-black-squeeze px-6 py-5"
              src="/images/landing/grade.webp"
              alt="Оценка кода"
            />
            <p class="rounded-xl bg-primary px-7 py-6 text-mobile leading-5">
              Полученный набор метрик подается на вход модели машинного обучения, которая
              преобразовывает их в оценку
            </p>
          </div>
        </div>
      </section>

      <!-- ECONOMY -->
      <section id="economy" class="flex scroll-mt-20 flex-col gap-10">
        <h3 class="text-headline-mobile font-bold text-madras md:text-headline-big">
          Экономический эффект
        </h3>
        <div
          class="flex flex-col gap-10 rounded-xl bg-black-squeeze px-3 py-5 md:flex-row md:px-10 md:py-8"
        >
          <div class="flex flex-col gap-5 text-mobile md:w-[50%] lg:text-base">
            <p>
              <span class="font-bold">Скрининг одного кандидата занимает около 15 минут</span>
              технического специалиста. Средняя ставка такого специалиста –
              <span class="font-bold">3000 рублей/час.</span> Скрининг одного кандидата обходится в
              <span class="font-bold">750 рублей.</span>
            </p>
            <p>
              <span class="font-bold">Среднее количество</span> кандидатов, которых отсматривает
              технический специалист для закрытия вакансии –
              <span class="font-bold">35 человек</span>
            </p>
            <p>Скрининг 35 кандидатов –<span class="font-bold"> 26 250 рублей</span></p>
          </div>

          <div
            class="flex flex-col gap-5 rounded-5xl bg-primary-pattern bg-cover bg-center px-4 py-9 text-center text-white md:w-[50%] md:gap-3 md:px-16"
          >
            <p class="text-bolder font-bold">
              Foxtailbox устраняет технического специалиста из процесса скрининга
            </p>
            <p class="text-base">Затраты на автоматическую проверку кода с Foxtailbox</p>
            <p class="text-mobile line-through">399 x 35 = 13 965</p>
            <p class="text-2xl font-bold">99 x 35 = 3 465</p>
          </div>
        </div>
      </section>

      <!-- HOW WORK -->
      <section class="flex flex-col gap-10">
        <h3 class="max-w-[520px] text-headline-mobile font-bold md:text-headline-big">
          Как работает сервис автоматической проверки
        </h3>
        <div class="flex flex-col gap-5 md:grid md:grid-cols-2 md:gap-10">
          <ol class="flex flex-col gap-5">
            <li class="flex gap-5 rounded-xl bg-black-squeeze px-8 py-3 text-base">
              <span class="text-xl font-bold">1.</span> Вы загружаете ссылку из репозитория,
              предоставленную кандидатом
            </li>
            <li class="flex gap-5 rounded-xl bg-black-squeeze px-8 py-3 text-base">
              <span class="text-xl font-bold">2.</span> Система делает расчет по пяти показателям,
              которые используются для оценки в рамках Best coding practices
            </li>
            <li class="flex flex-col rounded-xl bg-black-squeeze px-8 py-3 text-base">
              <span class="flex gap-5">
                <span class="text-xl font-bold">3.</span>
                <span class="flex flex-col gap-5">
                  Код оценивается по 10-балльной шкале:
                  <span class="flex flex-wrap gap-2 whitespace-nowrap text-mobile md:grid-cols-4">
                    <span class="flex justify-center rounded-xl bg-white p-2">1-3 Junior</span>
                    <span class="flex justify-center rounded-xl bg-white p-2">4-6 Middle</span>
                    <span class="flex justify-center rounded-xl bg-white p-2">7-8 Middle+</span>
                    <span class="flex justify-center rounded-xl bg-white p-2">9-10 Senior</span>
                  </span>
                </span>
              </span>
            </li>
          </ol>
          <img
            src="/images/landing/grade-rate.webp"
            alt="Пример, как выглядит автоматическая оценка кода"
          />
        </div>
      </section>

      <!-- HOW USE -->
      <section class="md:ga-10 flex grid-cols-2 flex-col-reverse items-center gap-16 md:grid">
        <img
          src="/images/landing/interface.webp"
          alt="Как зарегистрироваться в системе"
          class="-z-10 -mb-[180px] md:z-0 md:mb-0"
        />
        <div class="flex flex-col gap-12">
          <h4 class="text-2xl font-bold">Что нужно, чтобы начать пользоваться?</h4>
          <ol class="number-list text-mobile lg:text-base">
            <li class="font-bold">Регистрируетесь на Foxtailbox.ru</li>
            <li>Выбираете количество проверок репозиториев (минимум&nbsp;10)</li>
            <li>Оплачиваете выставленный счет. Мы отправим его почту, указанную при регистрации</li>
            <li>
              После оплаты средства зачислятся на ваш счет – и вы сможете пользоваться сервисом
            </li>
          </ol>
        </div>
      </section>
    </div>

    <!-- PRICE -->
    <section id="price" class="mb-8 min-h-[460px] scroll-mt-20 md:mb-0">
      <div class="rounded-5xl bg-hero-mobile bg-cover md:bg-hero md:bg-center">
        <div class="flex flex-col justify-center gap-5 px-5 py-7 md:flex-row md:gap-10 md:pt-10">
          <div class="flex flex-col items-center justify-center gap-10">
            <h4 class="w-full text-center text-headline-big font-bold text-white">Тарифы</h4>
            <div class="flex w-full flex-col justify-center gap-5 md:flex-row">
              <Card primary class="md:shadow-lg lg:w-[369px]">
                <div class="flex flex-col gap-4 px-5 pb-8 pt-7 text-center">
                  <p class="text-headline font-bold leading-6">Автоматическая проверка кода</p>
                  <p class="text-xl line-through">399руб</p>
                  <p class="text-3xl font-bold">99 руб</p>
                  <p class="text-xl">за проверку одного репозитория</p>
                  <p class="rounded-xl bg-black-squeeze px-4 pb-2 pt-1">
                    Дарим <span class="font-bold">5 бесплатных</span> проверок при&nbsp;регистрации
                  </p>

                  <div>
                    <NuxtLink :to="{ name: 'evaluation' }">
                      <Button class="px-14 py-4"> Проверить</Button>
                    </NuxtLink>
                  </div>
                </div>
              </Card>

              <Card class="md:shadow-lg lg:w-[369px]">
                <div class="flex flex-col gap-5 px-8 pb-8 pt-7 text-center lg:justify-between">
                  <p class="text-headline font-bold leading-6">Ручная проверка</p>
                  <div>
                    <p class="text-3xl font-bold">3500 руб/час</p>
                    <p class="mt-2 text-xl">за привлечение нашего эксперта</p>
                  </div>
                  <div>
                    <Button class="px-14 py-4" color="gray" @click="showRequest"> Заказать</Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped lang="scss">
.list {
  li {
    padding-left: 12px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      background-color: black;
      border-radius: 50%;
    }
  }
}

.list-small {
  li {
    padding-left: 12px;
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translate(-50%, -50%);
      width: 3px;
      height: 3px;
      background-color: black;
      border-radius: 50%;
    }
  }
}

.number-list {
  counter-reset: custom-counter;
  list-style-type: none;
  display: flex;
  flex-flow: column;
  gap: 20px;

  li::before {
    counter-increment: custom-counter;
    content: counter(custom-counter) '. ';
  }
}
</style>
